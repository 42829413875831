/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BiChat } from 'react-icons/bi';
import { FaCheckCircle } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { IoMdCloseCircle } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';

import { ICandidate, NotesType, UpdatedNotes } from '@/@types/candidate';
import BackHeader from '@/components/BackHeader';
import Container from '@/components/Container';
import FileDropContainer from '@/components/FileDropContainer';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import {
  fetchCandidateDetail,
  updateNotesDetail,
  uploadResume,
} from '@/services/candidate';
import { removeSegmentsFromUrl } from '@/utils/common';

import EditModal from '../Candidate/components/EditModal';
import ProfileStatus from '../Dashboard/components/ProfileStatus';

import CandidateInfo from './components/CandidateInfo';

export interface FileDropContainerRef {
  clearFileUpload: () => void;
}

const CvViewer: React.FC<{ cvUrl: string }> = ({ cvUrl }) => {
  return (
    <div className='w-full h-full p-2 pb-0'>
      <iframe
        src={cvUrl}
        title='CV Viewer'
        className='rounded-md w-full h-full'
      ></iframe>
    </div>
  );
};

const CandidateDetail = () => {
  const staticText = strings.candidateDetails;
  const fileDropRef = useRef<FileDropContainerRef>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { hasSubRole } = useAuth();
  const navigate = useNavigate();
  const { setActiveChat } = useChatContext();
  const { id } = useParams<{ id: string }>();
  const {
    fromTicketScreen,
    setFromTicketScreen,
    fromApprovalScreen,
    setFromApprovalScreen,
  } = useSidebarContext();

  const {
    editableCandidateData,
    setEditableCandidateData,
    setOpenEditModal,
    openEditModal,
  } = useCandidate();

  const [isEdit, setIsEdit] = useState(false);
  // const [numPages, setNumPages] = useState<number>();
  const [resume, setResume] = useState<File | null>(null);
  // const [pageNumber, setPageNumber] = useState<number>(1);
  // const [containerWidth, setContainerWidth] = useState<number | undefined>();
  const [candidateData, setCandidateData] = useState<ICandidate | null>(null);
  const [resumeProgress, setResumeProgress] = useState(0);

  const { data, isLoading, refetch } = useGenericQuery(
    ['candidate-detail', id],
    () => fetchCandidateDetail(id!),
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<NotesType>({
    mode: 'all',
    defaultValues: {
      notes: (candidateData as ICandidate)?.notes || '',
    },
  });

  const updateNotesMutation = useGenericMutation<
    { id: string; notes: UpdatedNotes },
    UpdatedNotes | boolean
  >(({ id, notes }) => updateNotesDetail(id, notes), {
    onSuccess: (response) => {
      if (response) {
        refetch();
        toast.success(staticText.notesSuccess);
        setIsEdit(false);
      }
    },
  });

  const onSubmit: SubmitHandler<NotesType> = (data) => {
    if (!id) return;
    const payload = {
      notes: data.notes,
    };
    updateNotesMutation.mutate({ id, notes: payload });
  };

  const handleResumeProgress = useCallback((percentage: number) => {
    setResumeProgress(percentage);
  }, []);

  const uploadResumeMutation = useGenericMutation<
    { resume: File; candidate: string } & {
      setResumeProgress?: (progress: number) => void;
    },
    ICandidate | boolean
  >(uploadResume, {
    onSuccess: () => {
      refetch();
      setResumeProgress(0);
      fileDropRef.current?.clearFileUpload();
    },
  });

  const handleSaveOrChatButton = () => {
    if (resume) {
      setResumeProgress(0);
      uploadResumeMutation.mutate({
        resume: resume as File,
        candidate: candidateData?._id || '',
        setResumeProgress: handleResumeProgress,
      });
    }
    if (isEdit) {
      handleSubmit(onSubmit)();
    } else if (candidateData && !resume) {
      // setActiveChat(null);
      navigate(ROUTES.CHAT);
      setActiveChat({ user: candidateData, ticket: null });
    }
  };

  const handleEditOrCancelButton = () => {
    if (resume) {
      setResume(null);
      fileDropRef.current?.clearFileUpload();
    } else {
      if (isEdit) {
        setIsEdit(false);
        reset({ notes: (data as ICandidate).notes });
      } else {
        setIsEdit(true);
      }
    }
  };

  const handleEditTagModalOpen = () => {
    if (candidateData) {
      setEditableCandidateData(candidateData);
      setOpenEditModal(!openEditModal);
    }
  };

  useEffect(() => {
    if (typeof data === 'object') {
      setCandidateData(data);
      reset({ notes: (data as ICandidate).notes });
    }
  }, [data]);

  const handleBackButton = () => {
    if (fromTicketScreen) {
      navigate(fromTicketScreen);
      setFromTicketScreen('');
    } else if (fromApprovalScreen) {
      navigate(fromApprovalScreen);
      setFromApprovalScreen('');
    } else {
      removeSegmentsFromUrl(navigate, location.pathname);
    }
  };

  // const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
  //   setNumPages(numPages);
  // };

  // const goToNextPage = () =>
  //   setPageNumber(
  //     pageNumber + 1 >= (numPages || 1) ? numPages || 1 : pageNumber + 1,
  //   );

  // useEffect(() => {
  //   if (containerRef.current) {
  //     setContainerWidth(containerRef.current.offsetWidth);
  //     const handleResize = () =>
  //       setContainerWidth(containerRef.current?.offsetWidth);
  //     window.addEventListener('resize', handleResize);
  //     return () => window.removeEventListener('resize', handleResize);
  //   }
  //   return undefined;
  // }, []);

  if (!isLoading && !data) return <StateIndicator state='Error' />;
  return (
    <>
      <div className='flex flex-col sm:flex-row gap-5 sm:gap-0 justify-between mb-5'>
        <BackHeader title={staticText.title} handleBackBtn={handleBackButton} />
        {!hasSubRole ? (
          <div className='flex gap-3 justify-end sm:justify-normal'>
            <Button
              variant='outline'
              className={cn(
                'rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base',
                { 'px-2 text-mouseGrey border-mouseGrey': isEdit || resume },
              )}
              icon={
                isEdit || resume ? (
                  <IoMdCloseCircle size={20} />
                ) : (
                  <FiEdit size={20} />
                )
              }
              onClick={handleEditOrCancelButton}
              disabled={
                updateNotesMutation.isPending || uploadResumeMutation.isPending
              }
              loading={
                updateNotesMutation.isPending || uploadResumeMutation.isPending
              }
            >
              {isEdit || resume ? strings.cancel : staticText.edit}
            </Button>
            <Button
              variant='outline'
              className='rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base'
              icon={
                isEdit || resume ? (
                  <FaCheckCircle size={16} />
                ) : (
                  <BiChat size={21} />
                )
              }
              onClick={handleSaveOrChatButton}
              disabled={
                (isEdit ? !isDirty || updateNotesMutation.isPending : false) ||
                uploadResumeMutation.isPending
              }
            >
              {isEdit || resume ? strings.save : staticText.chat}
            </Button>
          </div>
        ) : null}
      </div>
      {candidateData?.tags?.length ? (
        <ProfileStatus tags={candidateData?.tags} />
      ) : null}

      <div className='flex flex-col gap-6'>
        {/* <Container className='h-auto md:px-9 md:py-7'>
          <ResumeCard
            file={{
              name: isLoading
                ? ''
                : getFileNameFromUrl(candidateData?.resume || ''),
              size: candidateData?.fileSize || '-',
              url: candidateData?.resume || '',
              date: candidateData?.uploadedDate,
            }}
            isLoading={isLoading}
          />
        </Container> */}
        <div className='lg:grid grid-cols-5 gap-7 space-y-7 lg:space-y-0'>
          <div className='col-span-3'>
            <CandidateInfo
              data={candidateData!}
              isEdit={isEdit}
              control={control}
              isLoading={isLoading}
              handleEditTagModalOpen={handleEditTagModalOpen}
            />
          </div>

          <Container
            className='col-span-2 md:px-0 px-0 py-0 md:pt-0 h-[600px]'
            ref={containerRef}
          >
            {/* <Typography variant='subheading' className='font-bold'>
              {staticText.uploadedDocument}
            </Typography>
            <div className='mt-3 flex flex-col gap-y-2'>
              {candidateData?.documents?.length ? (
                candidateData?.documents
                  ?.sort((a) => (a.url ? -1 : 1))
                  .map((item) => (
                    <DownloadableFileRow
                      key={item.name}
                      name={item.name}
                      url={item.url}
                    />
                  ))
              ) : (
                <Typography className='my-10 text-center'>
                  {staticText.noFiles}
                </Typography>
              )}
            </div> */}
            {isLoading ? (
              <div className='h-[500px]'>
                <StateIndicator state='Loading' />
              </div>
            ) : (
              <>
                {/* <h1>Sample react-doc-viewer</h1>
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri: candidateData?.resume || '',
                    },
                  ]}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: false,
                      retainURLParams: false,
                    },
                  }}
                  style={{ height: 500 }}
                /> */}
                {candidateData?.resume ? (
                  // <>
                  //   <Document
                  //     file={candidateData?.resume}
                  //     onLoadSuccess={onDocumentLoadSuccess}
                  //     loading={
                  //       <div className='h-[500px]'>
                  //         <StateIndicator state='Loading' />
                  //       </div>
                  //     }
                  //     error={
                  //       <div className='h-[500px] px-5'>
                  //         <StateIndicator state='Error' />
                  //       </div>
                  //     }
                  //   >
                  //     <Page pageNumber={pageNumber} width={containerWidth} />
                  //   </Document>
                  //   {candidateData?.resume && numPages ? (
                  //     <nav className='flex gap-1 items-center justify-center my-3'>
                  //       <Button
                  //         onClick={() =>
                  //           setPageNumber(Math.max(1, pageNumber - 1))
                  //         }
                  //         size='icon'
                  //         icon='<'
                  //         disabled={pageNumber === 1}
                  //         className='size-7 rounded-md '
                  //       />
                  //       <Typography className='px-2 font-semibold'>
                  //         Page {pageNumber} of {numPages}
                  //       </Typography>
                  //       <Button
                  //         onClick={goToNextPage}
                  //         disabled={pageNumber === numPages}
                  //         size='icon'
                  //         icon='>'
                  //         className='size-7 rounded-md '
                  //       />
                  //     </nav>
                  //   ) : null}
                  // </>
                  <CvViewer
                    cvUrl={`https://docs.google.com/gview?url=${encodeURIComponent(candidateData?.resume || '')}&embedded=true`}
                  />
                ) : (
                  <div className='p-3 '>
                    <Typography className='text-base md:text-lg font-semibold'>
                      {staticText.uploadResume}
                    </Typography>
                    <FileDropContainer
                      buttonText='Upload Resume'
                      setFile={setResume}
                      fileName={resume ? resume.name : ''}
                      dropzoneClassName='w-full h-28 p-2 mt-3'
                      uploadingDivClassName='w-full h-28'
                      iconClassName='bg-primary rounded-full text-white text-[40px] p-2'
                      acceptedFileFormat={['.pdf', '.docx']}
                      disabled={uploadResumeMutation.isPending || isEdit}
                      ref={fileDropRef}
                      progress={resume ? 0 : resumeProgress}
                    />
                  </div>
                )}
              </>
            )}
          </Container>
        </div>
      </div>
      {openEditModal && editableCandidateData ? (
        <EditModal refetch={refetch} />
      ) : null}
    </>
  );
};

export default CandidateDetail;
