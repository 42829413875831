/* eslint-disable max-lines */
import { Dispatch, SetStateAction } from 'react';

import { FieldType, IDropdownOption, variantType } from '@/@types';
import {
  ICandidate,
  ICandidateTag,
  IPersonnelActionFormData,
  IRecruiterInterviewEvaluationFormData,
  ItemType,
  WorkExperience,
} from '@/@types/candidate';
import { ITicket } from '@/@types/tickets';
import { strings } from '@/locales';
import { EnumToArray, formatCurrency } from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';

import { INPUT_ERRORS, USER_TYPE } from '.';

const { candidateDetails } = strings;

export enum CANDIDATE_STATUS {
  ACTIVE = 'Active',
  SHORTLISTED = 'Shortlisted',
  ASSESSMENT = 'Assessment',
  INTERVIEWING = 'Interviewing',
  INTERVIEW_CLEARED = 'Interview Cleared',
  ENDORSED = 'Endorsed',
  PRE_DEPLOY = 'Pre-Deploy',
  HR_ORIENTATION = 'HR Orientation',
  ON_HOLD = 'On Hold',
  REJECTED = 'Rejected',
}

export const CandidateStatusList = EnumToArray(CANDIDATE_STATUS);

export const statusTransitions = (
  currentStatus: CANDIDATE_STATUS,
  selectedTicket: ITicket & { tags: ICandidateTag[] },
  selectedTagId: string,
  override?: boolean | null,
  profileType?: USER_TYPE,
) => {
  const previousStatus = selectedTicket?.tags?.find(
    (tag) => tag._id === selectedTagId,
  )?.previousStatus;

  const statuses = {
    [CANDIDATE_STATUS.ACTIVE]: [
      CANDIDATE_STATUS.ACTIVE,
      CANDIDATE_STATUS.SHORTLISTED,
    ],
    [CANDIDATE_STATUS.SHORTLISTED]: [
      CANDIDATE_STATUS.ACTIVE,
      CANDIDATE_STATUS.SHORTLISTED,
      CANDIDATE_STATUS.ASSESSMENT,
      CANDIDATE_STATUS.INTERVIEWING,
    ],
    [CANDIDATE_STATUS.ASSESSMENT]: [
      CANDIDATE_STATUS.ASSESSMENT,
      CANDIDATE_STATUS.INTERVIEWING,
      CANDIDATE_STATUS.ON_HOLD,
      CANDIDATE_STATUS.REJECTED,
    ],
    [CANDIDATE_STATUS.INTERVIEWING]: [
      CANDIDATE_STATUS.INTERVIEWING,
      CANDIDATE_STATUS.INTERVIEW_CLEARED,
      CANDIDATE_STATUS.ON_HOLD,
      CANDIDATE_STATUS.REJECTED,
    ],
    [CANDIDATE_STATUS.INTERVIEW_CLEARED]: [
      CANDIDATE_STATUS.INTERVIEW_CLEARED,
      ...(selectedTicket?.override
        ? [CANDIDATE_STATUS.PRE_DEPLOY]
        : [CANDIDATE_STATUS.ENDORSED]),
      CANDIDATE_STATUS.REJECTED,
    ],
    [CANDIDATE_STATUS.ENDORSED]:
      !override && profileType === USER_TYPE.RECRUITER
        ? [CANDIDATE_STATUS.ENDORSED]
        : [
            CANDIDATE_STATUS.ENDORSED,
            CANDIDATE_STATUS.PRE_DEPLOY,
            CANDIDATE_STATUS.REJECTED,
          ],
    [CANDIDATE_STATUS.ON_HOLD]: [
      CANDIDATE_STATUS.ON_HOLD,
      ...(previousStatus === CANDIDATE_STATUS.SHORTLISTED
        ? [CANDIDATE_STATUS.ASSESSMENT]
        : []),
      ...(previousStatus === CANDIDATE_STATUS.ASSESSMENT
        ? [CANDIDATE_STATUS.INTERVIEWING]
        : []),
      ...(previousStatus === CANDIDATE_STATUS.INTERVIEWING
        ? [CANDIDATE_STATUS.INTERVIEW_CLEARED]
        : []),
    ],
    [CANDIDATE_STATUS.HR_ORIENTATION]: [CANDIDATE_STATUS.HR_ORIENTATION],
    [CANDIDATE_STATUS.PRE_DEPLOY]: [
      CANDIDATE_STATUS.PRE_DEPLOY,
      CANDIDATE_STATUS.HR_ORIENTATION,
    ],
    [CANDIDATE_STATUS.REJECTED]: [CANDIDATE_STATUS.REJECTED],
  };

  const currentStatusIndex =
    EnumToArray(CANDIDATE_STATUS).indexOf(currentStatus);
  const previousStatuses = EnumToArray(CANDIDATE_STATUS).slice(
    0,
    currentStatusIndex,
  );
  const nextStatuses = statuses[currentStatus] || [];

  return profileType === USER_TYPE.ADMIN
    ? [...previousStatuses, ...nextStatuses]
    : nextStatuses;
};

export enum CANDIDATE_TOPBAR_ACTION {
  // REMOVE_FROM_SHORTLISTED = 'Remove from Shortlisted Candidates',
  // MOVE_TO_SHORTLISTED = 'Move to Shortlisted Candidates',
  DOWNLOAD_RESUME = 'Download Resume',
  DELETE = 'Delete',
}

export const CandidateTopBarActionList = EnumToArray(CANDIDATE_TOPBAR_ACTION);

export enum CANDIDATE_TABLE_ACTION {
  EDIT_TAGS = 'Edit Tag(s)',
  DELETE = 'Delete',
}

export const CandidateTableActionList = (hasResume: boolean) => [
  {
    value: 'editTags',
    label: CANDIDATE_TABLE_ACTION.EDIT_TAGS,
    isDisabled: !hasResume,
  },
  {
    value: 'delete',
    label: CANDIDATE_TABLE_ACTION.DELETE,
  },
];

// export enum CANDIDATE_TABS {
//   ALL = 'all',
//   SHORTLISTED = 'shortlisted',
// }

// export const CandidateTabsList = EnumToArray(CANDIDATE_TABS);

export const getInfoItems = (data?: ICandidate): ItemType[] => {
  return [
    {
      title: candidateDetails.name,
      description: `${data?.userDetails?.firstName} ${data?.userDetails?.lastName}`,
    },
    {
      title: candidateDetails.emailAddress,
      description: data?.userDetails?.email,
    },
    { title: candidateDetails.contactNumber, description: data?.contactNo },
    { title: candidateDetails.country, description: data?.country },
    {
      title: candidateDetails.blockOrUnitOrNumber,
      description: data?.blockOrUnitOrNumber,
    },
    { title: candidateDetails.city, description: data?.city },
    {
      title: candidateDetails.birthday,
      description: getLocalDate(data?.birthDate),
    },
    {
      title: candidateDetails.age,
      description: data?.age,
    },
    // {
    //   title: candidateDetails.positionDesired,
    //   description: data?.positionDesired?.[0],
    // },
    // { title: candidateDetails.department, description: data?.department },
    // { title: candidateDetails.zipCode, description: data?.zipCode },
    // { title: candidateDetails.experience, description: data?.experienceYears },
  ].filter(Boolean) as ItemType[];
};

export const generateCandidateFilterFields = (
  companies: string[],
  haveMoreCompanyPage: boolean | null,
  setCompanyPage: Dispatch<SetStateAction<number>>,
  setCompanySearch: Dispatch<SetStateAction<string>>,
  loadingCompanies: boolean | null,
  department: IDropdownOption[],
  haveMoreDepartmentPage: boolean | null,
  setDepartmentPage: Dispatch<SetStateAction<number>>,
  setDepartmentSearch: Dispatch<SetStateAction<string>>,
  loadingDepartments: boolean | null,
) => {
  return [
    {
      name: 'company',
      items: companies,
      isLoading: loadingCompanies,
      props: {
        haveMoreOptions: !!haveMoreCompanyPage,
        setPage: setCompanyPage,
        setInputSearch: setCompanySearch,
        isSearchable: true,
      },
    },
    {
      name: 'department',
      items: department,
      isLoading: loadingDepartments,
      props: {
        haveMoreOptions: !!haveMoreDepartmentPage,
        setPage: setDepartmentPage,
        setInputSearch: setDepartmentSearch,
        isSearchable: true,
      },
    },
    {
      name: 'tags',
      items: CandidateStatusList,
      props: { tagsDropdown: true, hasSearchBox: false },
    },
  ];
};

export const APPLIED_FILTERS_PILLS: { [key: string]: variantType } = {
  filterCompany: 'blue',
  filterDepartment: 'pink',
  company: 'blue',
  department: 'pink',
  jobTitle: 'green',
  name: 'yellow',
  ticketNumber: 'purple',
  tags: 'orange',
  assignee: 'yellow',
  recruiter: 'yellow',
  companyName: 'blue',
  postedOn: 'green',
  rateMax: 'purple',
  rateMin: 'purple',
};
export enum CANDIDATE_FILTER_MODAL_FILEDS {
  COMPANY = 'company',
  FILTER_COMPANY = 'filterCompany',
  DEPARTMENT = 'department',
  FILTER_DEPARTMENT = 'filterDepartment',
}
export const getFieldsForModal = ({
  companyList,
  ticketDetails,
  isLoading,
  companyName,
  ticketValue,
  statusList,
  isTicketScreen,
  setCompanyPage,
  moreCompaniesAvailable,
}: {
  companyList?: string[];
  ticketDetails?: { label: string; value: string }[];
  isLoading: boolean;
  companyName?: string;
  ticketValue?: IDropdownOption | string;
  statusList?: string[];
  isTicketScreen?: boolean;
  setCompanyPage: React.Dispatch<React.SetStateAction<number>>;
  moreCompaniesAvailable: boolean;
}) => [
  ...(!isTicketScreen
    ? [
        {
          title: strings.candidateScreen.company,
          name: 'companyName',
          options: companyList,
          error: INPUT_ERRORS.COMPANY_NAME.required,
          isLoading: isLoading,
          isSearchable: true,
          setPage: setCompanyPage,
          haveMoreOptions: moreCompaniesAvailable,
        },
      ]
    : []),
  {
    title: strings.candidateScreen.ticket,
    name: 'ticket',
    options: ticketDetails,
    error: INPUT_ERRORS.TICKET.required,
    isLoading: isLoading,
    isSearchable: true,
    isDisabled: !companyName,
    menuPlacement: 'top',
  },
  {
    title: strings.candidateScreen.status,
    name: 'status',
    options: statusList,
    error: INPUT_ERRORS.STATUS.required,
    tagsDropdown: true,
    isDisabled: (!companyName || !ticketValue) && !isTicketScreen,
    menuPlacement: 'top',
  },
];
export enum ALLOWANCE {
  FIXED = 'Fixed',
  PRORATED = 'Prorated',
  OTHER = 'Other',
}
export enum RATE {
  DAILY_RATE = 'Daily Rate',
  FIXED_MONTHLY = 'Fixed Monthly',
}
export enum DAILY_RATE_OPTIONS {
  DAYS_22 = '22 Days',
  DAYS_26 = '26 Days',
}
export enum HIRE_TYPE {
  NEW_HIRE = 'New Hire',
  CONTRACT_RENEWAL = 'Contract Renewal',
  RE_HIRE = 'Re-Hire',
}

export const personnelActionFormDataInitialState: IPersonnelActionFormData = {
  startDate: undefined,
  endDate: undefined,
  basic: '',
  sae: '',
  cola: '',
  ctpa: '',
  hireType: undefined,
  allowance: undefined,
  rateType: undefined,
  dailyRate: undefined,
};

export const PAN_FIELDS = (startDate: Date, endDate: Date) => [
  {
    name: 'startDate',
    inputType: FieldType.DatePicker,
  },
  {
    name: 'endDate',
    inputType: FieldType.DatePicker,
    rules: {
      validate: () => {
        if (startDate && new Date(endDate) < new Date(startDate)) {
          return INPUT_ERRORS.END_DATE.validate;
        }
        return true;
      },
    },
  },
  { name: 'basic' },
  { name: 'sae' },
  { name: 'cola' },
  { name: 'ctpa' },
];
export const createPanFormConstantData = ({
  rate,
  client,
  typeOfEmployment,
  locationOrArea,
  fillingDate,
}: {
  rate: number;
  client: string;
  typeOfEmployment: string;
  locationOrArea: string;
  fillingDate?: string;
}) => {
  return [
    {
      label: `${strings.candidateScreen.personnelActionForm.rate}:`,
      value: formatCurrency(rate),
    },
    {
      label: `${strings.candidateScreen.personnelActionForm.date}${fillingDate ? ' of Record' : ''}:`,
      value: getLocalDate(fillingDate || String(new Date())),
    },
    {
      label: strings.candidateScreen.personnelActionForm.client,
      value: client,
    },
    {
      label: strings.candidateScreen.personnelActionForm.typeOfEmployment,
      value: typeOfEmployment,
    },
    {
      label: strings.candidateScreen.personnelActionForm.locationOrArea,
      value: locationOrArea,
    },
  ];
};
export const RATE_FIELDS = [
  {
    name: 'rate.basic',
    label: strings.candidateScreen.personnelActionForm.basic,
  },
  {
    name: 'rate.ctpa',
    label: strings.candidateScreen.personnelActionForm.ctpa,
  },
  {
    name: 'rate.sae',
    label: strings.candidateScreen.personnelActionForm.sae,
  },
  {
    name: 'rate.cola',
    label: strings.candidateScreen.personnelActionForm.cola,
  },
];
export const createRecruiterInterviewEvaluationFormConstantData = ({
  candidate,
  assignedConsultant,
  jobPosition,
  expectedSalary,
}: {
  candidate: string;
  assignedConsultant: string;
  jobPosition: string;
  expectedSalary: string;
}) => {
  const staticText = strings.candidateScreen.recruiterInterviewEvaluationForm;
  return [
    {
      label: staticText.candidate,
      value: candidate,
    },
    {
      label: staticText.assignedConsultant,
      value: assignedConsultant,
    },
    {
      label: staticText.jobPosition,
      value: jobPosition,
    },
    {
      label: staticText.expectedSalary,
      value: expectedSalary,
    },
  ];
};

export enum CANDIDATE_SUITABLE_FOR {
  PASSED = 'PASSED',
  FOR_POOLING = 'FOR_POOLING',
  NQ = 'NQ',
}
export const CANDIDATE_SUITABLE_FOR_ARRAY = [
  { label: 'Passed', value: CANDIDATE_SUITABLE_FOR.PASSED },
  { label: 'For Pooling', value: CANDIDATE_SUITABLE_FOR.FOR_POOLING },
  { label: 'NQ', value: CANDIDATE_SUITABLE_FOR.NQ },
];

export const interviewEvaluationFormInitialValue: IRecruiterInterviewEvaluationFormData =
  {
    computerLiteracy: '',
    relevantWorkExperience: '',
    leadershipBackground: '',
    englishProficiency: null,
    communication: null,
    physicalAttributes: null,
    plansToMigrate: null,
    amenabilityWithFieldWork: null,
    amenabilityWithShiftingSchedule: null,
    availabilityForInterview: '',
    availabilityForEmployment: '',
    recommendationOrRemarks: '',
    suitableFor: null,
    dateOfAssessment: '',
  };

const textForInterviewEvaluationForm =
  strings.candidateScreen.recruiterInterviewEvaluationForm;

const ratingOption = ['1', '2', '3', '4', '5'];
const YesOrNoOption = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];
export const RECRUITER_INTERVIEW_EVALUATION_FORM_FIELDS = [
  {
    label: textForInterviewEvaluationForm.technicalCompetencies,
    isHeadingText: true,
  },
  {
    type: FieldType.TextArea,
    name: 'computerLiteracy',
    label: textForInterviewEvaluationForm.computerLiteracy,
    error: INPUT_ERRORS.COMPUTER_LITERACY.required,
  },
  {
    type: FieldType.TextArea,
    name: 'relevantWorkExperience',
    label: textForInterviewEvaluationForm.relevantWorkExperience,
    error: INPUT_ERRORS.RELEVANT_WORK_EXPERIENCE.required,
  },
  {
    type: FieldType.TextArea,
    name: 'leadershipBackground',
    label: textForInterviewEvaluationForm.leadershipBackground,
    error: INPUT_ERRORS.LEADERSHIP_BACKGROUND.required,
  },
  {
    label: textForInterviewEvaluationForm.others,
    isHeadingText: true,
  },
  {
    type: FieldType.RadioGroup,
    name: 'englishProficiency',
    label: textForInterviewEvaluationForm.englishProficiency,
    options: ratingOption,
    subText: textForInterviewEvaluationForm.englishProficiencySubText,
    error: INPUT_ERRORS.ENGLISH_PROFICIENCY.required,
  },
  {
    type: FieldType.RadioGroup,
    name: 'communication',
    label: textForInterviewEvaluationForm.communication,
    options: ratingOption,
    subText: textForInterviewEvaluationForm.communicationSubText,
    error: INPUT_ERRORS.COMMUNICATION.required,
  },
  {
    type: FieldType.RadioGroup,
    name: 'physicalAttributes',
    label: textForInterviewEvaluationForm.physicalAttributes,
    options: ratingOption,
    subText: textForInterviewEvaluationForm.physicalAttributesSubText,
    error: INPUT_ERRORS.PHYSICAL_ATTRIBUTES.required,
  },
  {
    type: FieldType.RadioGroup,
    name: 'plansToMigrate',
    label: textForInterviewEvaluationForm.plansToMigrate,
    options: YesOrNoOption,
    error: INPUT_ERRORS.PLANS_TO_MIGRATE.required,
  },
  {
    type: FieldType.RadioGroup,
    name: 'amenabilityWithFieldWork',
    label: textForInterviewEvaluationForm.amenabilityWithFieldWork,
    options: YesOrNoOption,
    error: INPUT_ERRORS.AMENABILITY_WITH_FIELD_WORK.required,
  },
  {
    type: FieldType.RadioGroup,
    name: 'amenabilityWithShiftingSchedule',
    label: textForInterviewEvaluationForm.amenabilityWithShiftingSchedule,
    options: YesOrNoOption,
    error: INPUT_ERRORS.AMENABILITY_WITH_SHIFTING_SCHEDULE.required,
  },
  {
    type: FieldType.TEXT_AREA_GROUP,
    fields: [
      {
        name: 'availabilityForInterview',
        label: textForInterviewEvaluationForm.availabilityForInterview,
        error: INPUT_ERRORS.AVAILABILITY_FOR_INTERVIEW.required,
      },
      {
        name: 'availabilityForEmployment',
        label: textForInterviewEvaluationForm.availabilityForEmployment,
        error: INPUT_ERRORS.AVAILABILITY_FOR_EMPLOYMENT.required,
      },
    ],
  },
  {
    type: FieldType.TextArea,
    name: 'recommendationOrRemarks',
    label: textForInterviewEvaluationForm.recommendationOrRemarks,
    error: INPUT_ERRORS.RECOMMENDATION_OR_REMARKS.required,
  },
  {
    type: FieldType.RadioGroup,
    label: textForInterviewEvaluationForm.suitableFor,
    name: 'suitableFor',
    options: CANDIDATE_SUITABLE_FOR_ARRAY,
    error: INPUT_ERRORS.SUITABLE_FOR.required,
  },
];

export const initialEndorsementFormState = {
  description: '',
  document: '',
};

export const getPersonnelActionFormHistoryData = (
  personnelActionFormDetail?: IPersonnelActionFormData,
) => {
  const staticText = strings.candidateScreen.personnelActionForm;
  return [
    {
      title: `${staticText.basic}: `,
      description: personnelActionFormDetail?.basic,
    },
    {
      title: `${staticText.sae}: `,
      description: personnelActionFormDetail?.sae,
    },
    {
      title: `${staticText.cola}: `,
      description: personnelActionFormDetail?.cola,
    },
    {
      title: `${staticText.ctpa}: `,
      description: personnelActionFormDetail?.ctpa,
    },
  ];
};
export const getRecruiterInterviewEvaluationFormHistory = (
  interviewEvaluationDetail?: IRecruiterInterviewEvaluationFormData,
) => {
  const { recruiterInterviewEvaluationForm: staticText } =
    strings.candidateScreen;
  return [
    {
      title: staticText.technicalCompetencies,
      isHeading: true,
    },
    {
      title: `${staticText.computerLiteracy}: `,
      description: interviewEvaluationDetail?.computerLiteracy,
    },
    {
      title: `${staticText.relevantWorkExperience}: `,
      description: interviewEvaluationDetail?.relevantWorkExperience,
    },
    {
      title: `${staticText.leadershipBackground}: `,
      description: interviewEvaluationDetail?.leadershipBackground,
    },
    {
      title: staticText.others,
      isHeading: true,
    },
    {
      title: staticText.englishProficiency,
      description: interviewEvaluationDetail?.englishProficiency,
      isRating: true,
    },
    {
      title: staticText.communication,
      description: interviewEvaluationDetail?.communication,
      isRating: true,
    },
    {
      title: staticText.physicalAttributes,
      description: interviewEvaluationDetail?.physicalAttributes,
      isRating: true,
    },
    {
      title: `${staticText.plansToMigrate}: `,
      className: 'flex gap-1',
      description: interviewEvaluationDetail?.plansToMigrate ? 'Yes' : 'No',
    },
    {
      title: `${staticText.amenabilityWithFieldWork}: `,
      className: 'flex gap-1',
      description: interviewEvaluationDetail?.amenabilityWithFieldWork
        ? 'Yes'
        : 'No',
    },
    {
      title: `${staticText.amenabilityWithShiftingSchedule}: `,
      className: 'flex gap-1',
      description: interviewEvaluationDetail?.amenabilityWithShiftingSchedule
        ? 'Yes'
        : 'No',
    },
    {
      title: `${staticText.availabilityForInterview}: `,
      description: interviewEvaluationDetail?.availabilityForInterview,
    },
    {
      title: `${staticText.availabilityForEmployment}: `,
      description: interviewEvaluationDetail?.availabilityForEmployment,
    },
    {
      title: `${staticText.recommendationOrRemarks}: `,
      description: interviewEvaluationDetail?.recommendationOrRemarks,
    },
    {
      title: `${staticText.suitableFor}: `,
      description:
        CANDIDATE_SUITABLE_FOR_ARRAY.find(
          (item) => item.value === interviewEvaluationDetail?.suitableFor,
        )?.label || '',
    },
  ];
};

export const getAcademicHistory = (data?: ICandidate) => {
  const academicData = data?.academicHistory || [];
  const { candidateDetails: staticText } = strings;

  return academicData.map((entry) => {
    const key = Object.keys(entry).find((k) =>
      ['college', 'vocational', 'highSchool', 'elementary'].includes(k),
    );
    return {
      heading: key ? staticText[key as keyof typeof staticText] : '-',
      name: key
        ? ((entry as unknown as Record<string, string>)[key] ?? '-')
        : '-',
      yearCovered: entry.yearCovered || '-',
      course: entry.course || '-',
    };
  });
};

export const workExperienceFields = (item: WorkExperience) => [
  {
    title: strings.candidateDetails.nameOfCompany,
    description: item?.nameOfCompany,
  },
  { title: strings.candidateDetails.position, description: item?.position },
  { title: strings.candidateDetails.salary, description: item?.salary || '' },
  {
    title: strings.candidateDetails.startDate,
    description: getLocalDate(item?.startDate),
  },
  {
    title: strings.candidateDetails.endDate,
    description: getLocalDate(item.endDate),
  },
];
