/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { FieldType, IDropdownOption, IPaginationResponse } from '@/@types';
import { ICandidate } from '@/@types/candidate';
import {
  ICompanyClientsResponse,
  IDeleteDocumentPayload,
  ITicket,
} from '@/@types/tickets';
import Container from '@/components/Container';
import FileDropContainer from '@/components/FileDropContainer';
import RadioButtonGroup from '@/components/RadioButtonGroup';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { AcceptedFileFormats } from '@/constants';
import {
  defaultTicketFormState,
  EmploymentTypeList,
  getEmploymentOptions,
  getFormattedData,
  HIRING_INFORMATION,
  MAN_POWER_REQUEST_REASON,
  ManPowerRequestReasonList,
  ReplacementNatureList,
  TICKET_INFORMATION,
} from '@/constants/ticket';
import useDebounce from '@/hooks/useDebounce';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { ROUTES } from '@/routes';
import { CreateTicketSchema } from '@/schema/CreateTicket';
import {
  createTicket,
  deleteDocument,
  fetchCompanyClients,
  fetchCompanyEmployees,
  updateTicket,
} from '@/services/ticket';
import { GetFormattedName } from '@/utils/common';
import { zodResolver } from '@hookform/resolvers/zod';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import AttachmentPreview from '../Chat/ChatArea/AttachmentPreview';

import FieldSelector from './components/FieldSelector';

export type CreateTicketType = z.infer<typeof CreateTicketSchema>;

interface Props {
  initialState?: ITicket;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  setDuplicateTicket?: React.Dispatch<React.SetStateAction<boolean>>;
  duplicate?: boolean;
}

const CreateTicket: React.FC<Props> = ({
  initialState,
  setIsEditing,
  duplicate,
  setDuplicateTicket,
}) => {
  const isRevision = setIsEditing && !duplicate;
  const {
    common,
    ticketDetailScreen: staticText,
    createTicketScreen,
  } = strings;

  const navigate = useNavigate();
  const { isAdmin, user } = useAuth();
  const queryClient = useQueryClient();

  const [clientPage, setClientPage] = useState(0);
  const [searchCity, setSearchCity] = useState('');
  const [employeePage, setEmployeePage] = useState(0);
  const [clientSearch, setClientSearch] = useState('');
  const [searchCountry, setSearchCountry] = useState('');
  const [employeeSearch, setEmployeeSearch] = useState('');
  const clientDebouncedSearchText = useDebounce(clientSearch);
  const employeeDebouncedSearchText = useDebounce(employeeSearch);

  const {
    watch,
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    setValue,
    resetField,
  } = useForm<CreateTicketType>({
    defaultValues: defaultTicketFormState(initialState),
    resolver: zodResolver(CreateTicketSchema),
    mode: 'all',
  });

  const companyName = watch('company')?.label;

  const deleteDocumentMutation = useGenericMutation<
    IDeleteDocumentPayload,
    string | boolean
  >(deleteDocument);

  const handleRemoveAttachment = (index: number) => {
    const attachments = watch('qualifications') || [];
    const file = attachments?.find((_, i) => i === index);
    if (typeof file === 'string') {
      deleteDocumentMutation.mutate({
        ticketId: initialState?._id || '',
        fileName: (file as string).split('/').pop() || '',
      });
    }
    const updatedAttachments = attachments.filter(
      (_, i) => i !== index,
    ) as File[];
    setValue('qualifications', updatedAttachments);
  };

  const {
    data: clientList,
    isLoading: loadingClients,
    hasNextPage: haveMoreCompanyClients,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'getCompanyClients',
      clientDebouncedSearchText,
      companyName,
      clientPage,
    ],
    queryFn: ({ pageParam = clientPage + 1 }) =>
      fetchCompanyClients(companyName, pageParam, clientDebouncedSearchText),
    initialPageParam: 1,
    enabled: !!companyName,
    getNextPageParam: (data, pages) => {
      if (typeof data === 'object' && pages.length < data.totalPages) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const companyClients = useMemo(() => {
    if (!clientList?.pages?.some(Boolean)) {
      return [];
    }

    return clientList.pages.filter(Boolean).flatMap((page) => {
      const results = (page as IPaginationResponse<ICompanyClientsResponse>)
        ?.results;
      return (
        results?.[0]?.companyClients?.map((client) => ({
          label: GetFormattedName(client?.userDetails),
          value: client?._id,
          avatar: client?.userDetails?.avatar as string,
        })) || []
      );
    });
  }, [clientList?.pages, clientDebouncedSearchText, companyName]);

  const handleReset = () => {
    if (setIsEditing) {
      setIsEditing(false);
      setDuplicateTicket?.(false);
    } else {
      navigate(-1);
    }
  };

  const {
    companies,
    setCompanyPage,
    loadingCompanies,
    department,
    setDepartmentPage,
    loadingDepartments,
    setDepartmentSearch,
    setCompanySearch,
    haveMoreCompanyPage,
    haveMoreDepartmentPage,
  } = useCommonDataContext();

  const createTicketMutation = useGenericMutation<ITicket, ITicket | boolean>(
    createTicket,
    {
      onSuccess: (response) => {
        if (response) {
          toast.success(createTicketScreen.success);
          handleReset();
          navigate(ROUTES.TICKETS, { replace: true });
        }
      },
    },
  );

  const updateTicketMutation = useGenericMutation<
    { id: string; payload: ITicket },
    ITicket | boolean
  >(({ id, payload }) => updateTicket(id, payload), {
    onSuccess: (response) => {
      if (response) {
        toast.success(createTicketScreen.updateSuccess);
        queryClient.invalidateQueries({
          queryKey: ['getTicketDetail'],
        });
        navigate(ROUTES.TICKETS, { replace: true });
        handleReset();
      }
    },
  });

  const handleCreateTicket = (data: CreateTicketType) => {
    const payload = getFormattedData(data, duplicate);
    createTicketMutation.mutate(payload as ITicket);
  };

  const handleUpdateTicket = (data: CreateTicketType) => {
    const payload = getFormattedData(data, true);

    updateTicketMutation.mutate({
      id: initialState!._id!,
      payload: payload as ITicket,
    });
  };
  const company = isAdmin ? watch('company').label : user?.companyName;
  const { data, isLoading } = useGenericQuery(
    ['employees', company, employeePage, employeeDebouncedSearchText],
    () =>
      fetchCompanyEmployees(
        company!,
        employeePage + 1,
        employeeDebouncedSearchText,
      ),
    { enabled: !!company },
  );

  const employeeList = useMemo(() => {
    if (typeof data === 'object') {
      return data?.results?.map((employee) => ({
        label: GetFormattedName(employee.userDetails),
        value: GetFormattedName(employee.userDetails),
        avatar: employee.userDetails?.avatar as string,
      }));
    }
    return [];
  }, [data]);

  const TicketInformation = TICKET_INFORMATION({
    isAdmin,
    editing: !!setIsEditing,
    departmentDropdownValues: department,
    companiesDropdownValues: companies,
    clientsDropdownValues: companyClients,
    loadingCompanies: loadingCompanies!,
    loadingDepartments: loadingDepartments!,
    loadingClients: loadingClients!,
    setCompanySearch,
    setDepartmentSearch,
    setClientPage,
    setCompanyPage,
    setDepartmentPage,
    setClientSearch,
    disableClientDropdown: !companyName,
    haveMoreCompanyPage,
    haveMoreDepartmentPage,
    haveMoreCompanyClients,
    duplicate,
  });

  useEffect(() => {
    if (companyName) {
      setValue('client', { label: '', value: '' });
      setClientSearch('');
    }
  }, [companyName, setValue]);

  useEffect(() => {
    if (haveMoreCompanyClients) {
      fetchNextPage();
    }
  }, [clientPage]);

  useEffect(() => {
    setValue('client', defaultTicketFormState(initialState).client);
  }, [companyClients]);

  const typeOfEmployment = watch('typeOfEmployment');
  const countryCode = (watch('assignmentCountry') as IDropdownOption)?.value;

  useEffect(() => {
    if (typeOfEmployment) {
      resetField('employmentDuration');
    } else resetField('assignmentCity');
  }, [typeOfEmployment, countryCode]);

  useEffect(() => {
    if (duplicate) {
      setValue('targetOnBoard', '');
      setValue('dateOfNOR', new Date());
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit(
        isRevision ? handleUpdateTicket : handleCreateTicket,
      )}
      className='flex flex-col items-end gap-6 overflow-scroll relative'
    >
      <Container className='flex flex-col gap-6'>
        <Typography variant='heading' className='md:text-2xl'>
          {isRevision ? createTicketScreen.edit : createTicketScreen.title}
        </Typography>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7'>
          {TicketInformation?.map((field) => (
            <FieldSelector
              {...field}
              key={field.name}
              errors={errors}
              control={control}
              inputType={field.inputType as FieldType}
              isRequired={field.name !== 'client'}
              saveBothLabelAndValue
              tooltipText={
                field.name === 'department' ||
                (field.name === 'department' && initialState?.override)
                  ? staticText.departmentOverride
                  : ''
              }
            />
          ))}
        </div>
        <Typography variant='heading' className='md:text-2xl'>
          {staticText.jobHiringDetails}
        </Typography>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7'>
          {HIRING_INFORMATION(
            searchCountry,
            searchCity,
            setSearchCountry,
            setSearchCity,
            countryCode,
            !!setIsEditing,
            duplicate,
          )?.map((field) => (
            <FieldSelector
              {...field}
              key={field.name}
              errors={errors}
              control={control}
              type={field.type}
              saveBothLabelAndValue
              isRequired={field.name !== 'endorsementDate'}
            />
          ))}
        </div>
        <div className='flex gap-6 flex-col lg:flex-row mt-2'>
          <div className='flex flex-col gap-5 lg:w-[45%]'>
            <Typography className='font-extrabold md:text-[15px]'>
              {staticText.reasonForManpowerRequest}
              <span className='text-redColor ml-1'>*</span>
            </Typography>
            <div>
              <Controller
                control={control}
                name='reasonForManPowerRequests'
                render={({ field: { value, onChange } }) => (
                  <RadioButtonGroup
                    errors={errors}
                    name='reasonForManPowerRequests'
                    value={value}
                    onChange={onChange}
                    options={ManPowerRequestReasonList}
                    containerClassName='flex-col h-fit gap-4 min-h-16 mb-1'
                    conditionalContent={(option) => {
                      return option === ManPowerRequestReasonList[0] &&
                        watch('reasonForManPowerRequests') ===
                          MAN_POWER_REQUEST_REASON.REPLACEMENT ? (
                        <div className='pl-10 flex flex-col gap-4 mt-2'>
                          <Typography className='font-bold md:text-[15px]'>
                            {staticText.replacementNature}
                            <span className='text-redColor ml-1'>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='nature'
                            render={({ field: { value, onChange } }) => (
                              <RadioButtonGroup
                                errors={errors}
                                name='nature'
                                value={value!}
                                onChange={onChange}
                                options={ReplacementNatureList}
                                containerClassName='flex-col h-fit gap-4'
                              />
                            )}
                          />
                          <div className='gap-4'>
                            <div className='sm:w-[80%]'>
                              <FieldSelector
                                name='replacedEmployee'
                                errors={errors}
                                control={control}
                                isRequired={true}
                                inputType={FieldType.Dropdown}
                                options={employeeList}
                                loading={isLoading}
                                disabled={!company}
                                saveBothLabelAndValue
                                setInputSearch={setEmployeeSearch}
                                setPage={setEmployeePage}
                                haveMoreOptions={
                                  employeePage + 1 <
                                  (data as IPaginationResponse<ICandidate>)
                                    ?.totalPages
                                }
                                creatable
                                placeholder={strings.searchOrType}
                              />
                            </div>
                            <div className='sm:w-[80%]'>
                              <FieldSelector
                                name='dateOfNOR'
                                errors={errors}
                                control={control}
                                inputType={FieldType.DatePicker}
                                isRequired={true}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null;
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex flex-col gap-5 lg:w-[45%] h-fit'>
            <Typography className='font-extrabold md:text-[15px]'>
              {staticText.typeOfEmployment}
              <span className='text-redColor ml-1'>*</span>
            </Typography>
            <Controller
              control={control}
              name='typeOfEmployment'
              render={({ field: { value, onChange } }) => (
                <RadioButtonGroup
                  errors={errors}
                  name='typeOfEmployment'
                  value={value!}
                  onChange={onChange}
                  options={EmploymentTypeList}
                  containerClassName='flex-col flex gap-4 h-full'
                  conditionalContent={(option) => {
                    const employmentType = watch('typeOfEmployment');
                    const options = getEmploymentOptions(
                      option,
                      employmentType,
                    );

                    if (!options) return;
                    return (
                      <Controller
                        control={control}
                        name='employmentDuration'
                        render={({ field: { value, onChange } }) => (
                          <RadioButtonGroup
                            errors={errors}
                            name='employmentDuration'
                            value={value!}
                            onChange={onChange}
                            options={options}
                            containerClassName='pl-10 flex flex-col gap-4 h-full mt-2'
                          />
                        )}
                      />
                    );
                  }}
                />
              )}
            />
          </div>
        </div>
        <div>
          <Typography className='font-extrabold md:text-[15px]'>
            {staticText.descriptionAndQualifications}
            <span className='text-redColor ml-1'>*</span>
          </Typography>
          <Controller
            name='jobDescription'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textarea
                rows={4}
                value={value as string}
                onChange={onChange}
                errors={errors}
                className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
              />
            )}
          />
        </div>
        <div>
          <Typography className='font-extrabold md:text-[15px]'>
            {staticText.otherDetails}
            {createTicketScreen.attachCompetency}
          </Typography>
          <Controller
            name='qualificationsDetails'
            control={control}
            render={({ field: { value, onChange } }) => (
              <Textarea
                rows={4}
                value={value}
                onChange={onChange}
                className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
              />
            )}
          />
          <Controller
            name='qualifications'
            control={control}
            render={({ field: { value } }) => (
              <div className='mt-4'>
                <FileDropContainer
                  setValue={setValue}
                  watch={watch}
                  acceptedFileFormat={AcceptedFileFormats}
                  dropzoneClassName='h-28 p-2 mb-2'
                  multiple
                />
                {value ? (
                  <div className='flex flex-wrap gap-3'>
                    {value.map((item, index) => (
                      <AttachmentPreview
                        key={`Qualifications-${index}`}
                        attachment={item}
                        onRemoveAttachment={() => handleRemoveAttachment(index)}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          />
        </div>
      </Container>
      <div className='flex gap-4 w-fit self-end'>
        <Button
          size='sm'
          variant='outline'
          className='rounded-xl'
          onClick={handleReset}
          disabled={
            createTicketMutation.isPending || updateTicketMutation.isPending
          }
        >
          {common.cancel}
        </Button>
        <Button
          type='submit'
          size='sm'
          className='rounded-xl'
          disabled={
            (!Object.keys(dirtyFields).length &&
              !!setIsEditing &&
              !duplicate) ||
            createTicketMutation.isPending ||
            updateTicketMutation.isPending
          }
          loading={
            createTicketMutation.isPending || updateTicketMutation.isPending
          }
        >
          {isRevision ? common.update : common.submit}
        </Button>
      </div>
    </form>
  );
};

export default CreateTicket;
