import React, { useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { FetchCandidateResponse } from '@/@types/candidate';
import FileDropContainer from '@/components/FileDropContainer';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { INPUT_ERRORS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { addCandidateList } from '@/services/candidate';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

interface IAddCandidatesModalProps {
  open: boolean;
  handleCloseModal: () => void;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<FetchCandidateResponse | boolean, unknown>>;
}
type AddCandidateProp = { candidate: File | null };

const AddCandidatesModal: React.FC<IAddCandidatesModalProps> = ({
  open,
  handleCloseModal,
  refetch,
}) => {
  const staticText = strings.addCandidateModal;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddCandidateProp>({
    defaultValues: { candidate: null },
    mode: 'all',
  });

  const uploadedFile = watch('candidate');

  const [uploadProgress, setUploadProgress] = useState(0);

  const handleProgressUpdate = useCallback((percentage: number) => {
    setUploadProgress(percentage);
  }, []);

  const addCandidateMutation = useGenericMutation(addCandidateList, {
    onSuccess: (response) => {
      handleCloseModal();
      reset();
      refetch();
      if (typeof response === 'string') {
        toast.success(response);
      }
    },
  });

  const handleAddCandidates: SubmitHandler<AddCandidateProp> = ({
    candidate,
  }) => {
    if (candidate) {
      setUploadProgress(0);
      addCandidateMutation.mutate({
        payload: candidate as File,
        setPercent: handleProgressUpdate,
      });
    }
  };

  return (
    <Modal
      open={open}
      handleCloseModal={handleCloseModal}
      title={staticText.title}
      primaryBtnLabel={staticText.add}
      primaryBtnAction={handleSubmit(handleAddCandidates)}
      buttonDisabled={addCandidateMutation.status === 'pending'}
      isLoading={addCandidateMutation.status === 'pending'}
    >
      <div className='w-full space-y-5'>
        <div className='flex flex-col'>
          <Typography className=' md:text-[15px]'>
            {staticText.sampleFileMessage}
            <a
              href='https://smoothmovesph-data-bucket.s3.ap-southeast-1.amazonaws.com/user/candidate-sample.csv'
              download
              target='_blank'
              rel='noreferrer'
              className='text-primary ml-2 font-bold'
            >
              {staticText.downloadSampleFile}
            </a>
          </Typography>
          <Typography className='md:text-[15px]'>
            {staticText.view}
            <a
              href='https://smoothmovesph-data-bucket.s3.ap-southeast-1.amazonaws.com/user/Platforms.PNG'
              target='_blank'
              rel='noreferrer'
              className='text-primary font-bold'
            >
              {staticText.here}
            </a>
          </Typography>
        </div>
        <div>
          <Typography className='font-extrabold md:text-[15px]'>
            {staticText.uploadFile}
            <span className='text-redColor ml-1'>*</span>
          </Typography>
          <Controller
            name='candidate'
            rules={{
              required: INPUT_ERRORS.FILE.required,
            }}
            control={control}
            render={({ field: { onChange } }) => (
              <FileDropContainer
                buttonText={staticText.uploadFile}
                setFile={onChange}
                acceptedFileFormat={['.csv']}
                iconClassName='bg-primary rounded-full text-white text-[40px] p-2'
                fileName={uploadedFile?.name}
                progress={uploadProgress}
              />
            )}
          />
          {errors.candidate && (
            <span className='text-redColor text-sm mt-1'>
              {errors.candidate.message}
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddCandidatesModal;
