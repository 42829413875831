import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { MdOutlineEdit } from 'react-icons/md';

import { ICandidate, NotesType } from '@/@types/candidate';
import Container from '@/components/Container';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Textarea } from '@/components/ui/textarea';
import {
  getAcademicHistory,
  getInfoItems,
  workExperienceFields,
} from '@/constants/candidate';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { EXTRACT_TYPE, getLabelOrValue, getTagVariant } from '@/utils/common';

interface Props {
  isEdit: boolean;
  data?: ICandidate;
  control: Control<NotesType>;
  isLoading?: boolean;
  handleEditTagModalOpen: () => void;
}

const CandidateInfo: React.FC<Props> = ({
  data,
  isEdit,
  control,
  isLoading,
  handleEditTagModalOpen,
}) => {
  const { candidateDetails: staticText } = strings;
  const { isAdmin, hasSubRole } = useAuth();

  // const getFamilyMemberLabelAndName = (member: IFamilyMember) => {
  //   if (member.nameOfSpouse) {
  //     return { label: staticText.spouse, name: member.nameOfSpouse };
  //   } else if (member.nameOfFather) {
  //     return { label: staticText.father, name: member.nameOfFather };
  //   } else if (member.nameOfMother) {
  //     return { label: staticText.mother, name: member.nameOfMother };
  //   }
  //   return { label: '', name: '' };

  return (
    <Container>
      <div className='flex flex-col gap-8 p-4 md:p-7'>
        <div className='space-y-8'>
          <div
            className={cn(
              'grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-7 gap-y-6 md:gap-y-10',
              { 'xl:grid-cols-3': isAdmin },
            )}
          >
            {getInfoItems(data)?.map((item, index) => (
              <div key={index} className='col-span-1'>
                {item.title === staticText.name ||
                item.title === staticText.recruiterName ? (
                  <div className='space-y-2'>
                    <Typography className='block font-bold sm:text-[15px]'>
                      {staticText.candidateName}
                    </Typography>
                    <ProfileBadge
                      name={item.description || ''}
                      profilePicture={data?.userDetails?.avatar as string}
                      className='justify-start'
                      isLoading={isLoading}
                    />
                  </div>
                ) : (
                  <NameAndDesc
                    title={item.title}
                    description={item.description}
                    className='col-span-1'
                    isLoading={isLoading}
                  />
                )}
              </div>
            ))}
            {/* <div>
              <Typography className='block font-bold md:text-[15px] truncate'>
                {staticText.familyBackground}
              </Typography>
              {(
                data?.familyEducationDetails as unknown as IFamilyMember[]
              )?.map((member) => {
                const { label, name } = getFamilyMemberLabelAndName(member);
                return (
                  <Typography
                    key={label}
                    className='block md:text-[15px] truncate font-semibold'
                  >
                    {label}
                    <span className='font-normal ml-1'>
                      {name && member.occupation
                        ? `${name}, ${member.occupation}`
                        : '-'}
                    </span>
                  </Typography>
                );
              })}
            </div> */}
            <div className='col-span-1 sm:col-span-2 xl:col-span-3 items-center '>
              <Accordion type='single' collapsible disabled={isLoading}>
                <AccordionItem
                  value={staticText.additionalInfo}
                  className='mt-1'
                >
                  <AccordionTrigger className='hover:no-underline gap-3 font-bold text-lg md:text-xl p-0 pb-2 bg-white'>
                    {staticText.additionalInfo}
                  </AccordionTrigger>
                  <AccordionContent className='flex flex-col gap-1 mt-2 gap-y-5 bg-white '>
                    <NameAndDesc
                      title={`${staticText.expectedSalary}:`}
                      description={data?.expectedSalary}
                      className='flex items-center space-y-0 gap-1'
                      isLoading={isLoading}
                    />
                    <div>
                      <Typography className='block font-bold text-base md:text-lg truncate mb-2'>
                        {staticText.academicHistory}
                      </Typography>
                      {getAcademicHistory(data).map((item) => (
                        <div key={item.name || item.heading} className='mb-3'>
                          <Typography className='block font-bold md:text-base truncate'>
                            {item.heading}
                          </Typography>
                          <div className='grid grid-cols-2 md:grid-cols-3 mt-1'>
                            {[
                              {
                                title: staticText.name,
                                description: item.name,
                              },
                              {
                                title: staticText.yearCovered,
                                description: item.yearCovered,
                              },
                              {
                                title: staticText.course,
                                description: item.course,
                              },
                            ].map(({ title, description }) => (
                              <NameAndDesc
                                key={title}
                                title={title}
                                description={description || '-'}
                                isLoading={isLoading}
                                className='space-y-0'
                              />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <Typography className='block font-bold text-base md:text-lg truncate mb-2'>
                        {staticText.workExperiences}
                      </Typography>
                      <div className='flex flex-col gap-y-5'>
                        {data?.workExperiences.map((item) => {
                          const fields = workExperienceFields(item);

                          return (
                            <div
                              key={item.nameOfCompany}
                              className='grid grid-cols-2 md:grid-cols-3 gap-y-3 '
                            >
                              {fields.map(({ title, description }) => (
                                <NameAndDesc
                                  key={title}
                                  title={title}
                                  description={description}
                                  isLoading={isLoading}
                                  className='space-y-0'
                                />
                              ))}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div>
          <div className='flex items-center gap-3 group'>
            <Typography className='font-bold'>{staticText.tags}</Typography>
            {!hasSubRole ? (
              <MdOutlineEdit
                onClick={handleEditTagModalOpen}
                className='w-5 text-xl text-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer'
              />
            ) : null}
          </div>
          <div className='mt-5 flex gap-3 flex-wrap'>
            {Array.isArray(data?.tags) && data?.tags.length ? (
              data?.tags.map((item) => (
                <Tags
                  key={`${item.companyName}-${item.ticket}`}
                  text={`${getLabelOrValue(item?.companyName, EXTRACT_TYPE.LABEL)}/ TK#${item.ticketNumber} / ${item?.isPendingApproval ? strings.common.pendingApproval : getLabelOrValue(item.status, EXTRACT_TYPE.LABEL)}`}
                  variant={getTagVariant(
                    item?.isPendingApproval
                      ? strings.common.pendingApproval
                      : (getLabelOrValue(
                          item?.status,
                          EXTRACT_TYPE.VALUE,
                        ) as string),
                  )}
                  containerClassName='w-fit'
                />
              ))
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
        <div>
          <Typography className='font-bold'>{staticText.notes}</Typography>
          {isEdit ? (
            <Controller
              control={control}
              name='notes'
              render={({ field: { onChange, value } }) => (
                <Textarea
                  rows={6}
                  value={value}
                  onChange={onChange}
                  className='resize-none mt-5 text-sm md:text-sm rounded-xl font-medium min-h-10 w-full text-primaryBlack max-h-80'
                />
              )}
            />
          ) : (
            <Typography className='mt-5 text-base block'>
              {data?.notes || '-'}
            </Typography>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CandidateInfo;
