import { IUser } from './auth';
import { ICandidate } from './candidate';
import { IRate, ITicket } from './tickets';

export interface IAPIResponse<T> {
  status: boolean | number;
  message: string;
  data: T | null;
  error?: string;
}

export interface IDropdownOption {
  label: string;
  value: string;
  avatar?: string;
  extra?: boolean;
  isDisabled?: boolean;
}

export type variantType =
  | 'green'
  | 'red'
  | 'blue'
  | 'yellow'
  | 'gray'
  | 'purple'
  | 'pink'
  | 'orange'
  | 'brown'
  | 'teal';

export interface IActiveChat {
  ticket?: ITicket | null;
  user: ICandidate | IUser;
}

export interface FilterOptions {
  [key: string]: string | number | IRate | IDropdownOption;
}

export type EnumType<T> = {
  [key in keyof T]: T[key] extends string | number ? T[key] : never;
};

export interface IPaginationResponse<T> {
  results: T[];
  totalPages: number;
}

export interface INameable {
  firstName?: string;
  lastName?: string;
}

export enum FieldType {
  DatePicker = 'Date Picker',
  Dropdown = 'Dropdown',
  Input = 'Input',
  RadioGroup = 'Radio Group',
  Range = 'Range',
  TextArea = 'Text Area',
  Checkbox = 'Checkbox',
  TEXT_AREA_GROUP = 'Text Area Group',
}

export interface FilterField {
  name: string;
  inputType?: FieldType;
}
