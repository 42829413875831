/* eslint-disable max-lines */
import React, { useCallback, useState } from 'react';

import { IDashboardManpowerSectionCompany } from '@/@types/dashboard';
import Container from '@/components/Container';
import StateIndicator from '@/components/StateIndicator';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Progress } from '@/components/ui/progress';
import {
  COLORS_FOR_MANPOWER_SECTION,
  DASHBOARD_MANPOWER_STATIC_FORM_DATA_FOR_MODAL,
  tooltipData,
} from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { manpowerRequestFormDdashboard } from '@/services/dashboard';
import { getRandomColorsFromArray } from '@/utils/common';

import ManpowerLoadingSkeleton from './ManpowerLoadingSkeleton';
import ManpowerSectionDetailItem from './ManpowerSectionDetailItem';

interface IManpowerRequestFormSection {
  cardDisplay?: boolean;
  haveCharts?: boolean;
}

const ManpowerRequestFormSection: React.FC<IManpowerRequestFormSection> = ({
  cardDisplay,
  haveCharts,
}) => {
  const { dashboard: staticText, common } = strings;
  const [openItems, setOpenItems] = useState<string | undefined>(undefined);
  const { sidebarExpand } = useSidebarContext();

  const {
    data: manpowerRequestFormData,
    isLoading: manpowerRequestFormDataLoading,
  } = useGenericQuery(
    ['manpowerRequestFormDdashboard'],
    manpowerRequestFormDdashboard,
  );

  const handleAccordionChange = useCallback(
    (value?: string) => setOpenItems(value),
    [],
  );

  const formatNumber = (num?: number): string | number => {
    if (!num) {
      return '00';
    } else if (num < 10) {
      return `0${num}`;
    }
    return num;
  };

  const displayedData = cardDisplay
    ? DASHBOARD_MANPOWER_STATIC_FORM_DATA_FOR_MODAL
    : manpowerRequestFormData;

  const totalVacancies = useCallback(() => {
    return Array.isArray(manpowerRequestFormData)
      ? manpowerRequestFormData.reduce(
          (sum, ticket) => sum + (ticket.totalVacancies || 0),
          0,
        )
      : 0;
  }, [manpowerRequestFormData]);

  const totalActiveReq = useCallback(() => {
    return Array.isArray(manpowerRequestFormData)
      ? manpowerRequestFormData.reduce(
          (sum, ticket) => sum + (ticket.activeRecruiter || 0),
          0,
        )
      : 0;
  }, [manpowerRequestFormData]);

  const totalNoOfPosition = useCallback(() => {
    return Array.isArray(manpowerRequestFormData)
      ? manpowerRequestFormData.reduce(
          (sum, item) => sum + (item.numberOfPositions || 0),
          0,
        )
      : 0;
  }, [manpowerRequestFormData]);

  return (
    <Container
      className={cn({
        'p-0 md:p-0 border-none rounded-lg pointer-events-none select-none':
          cardDisplay,
      })}
    >
      <div className='grid grid-rows-[auto_1fr] h-full'>
        {cardDisplay ? null : (
          <div className='mb-3'>
            <Typography className='font-bold'>
              {staticText.manpowerFormSection}
            </Typography>
            <div
              className={cn(
                'space-y-2 xs:space-y-0 lg:grid-cols-1 xs:grid grid-cols-3 xl:grid-cols-3 gap-2 mt-3 px-1',
                { 'mb-2': cardDisplay },
              )}
            >
              {tooltipData(
                totalVacancies(),
                totalActiveReq(),
                totalNoOfPosition(),
              ).map(({ text, label, value }) => (
                <TooltipComponent
                  key={label}
                  text={text}
                  align='center'
                  className='capitalize md:text-xs text-mouseGrey max-w-52'
                  directionalArrow
                  trigger={
                    <ManpowerSectionDetailItem
                      label={`${label}: `}
                      value={value}
                      loading={manpowerRequestFormDataLoading}
                      labelClassName='text-primary'
                      valueClassName='text-primary font-bold'
                      cardDisplay={cardDisplay}
                      containerClassName={cn(
                        '!bg-trafficWhite rounded-md text-center items-center w-full p-1.5 gap-1 shadow-sm justify-center space-y-0',
                        {
                          'p-1': cardDisplay,
                        },
                      )}
                      totals
                    />
                  }
                />
              ))}
            </div>
          </div>
        )}
        <div
          className={cn('overflow-y-auto scrollbarHidden', {
            'overflow-hidden': !manpowerRequestFormData,
          })}
        >
          <div
            className={cn('flex flex-col gap-3', {
              'gap-1': cardDisplay,
            })}
          >
            {manpowerRequestFormDataLoading && !cardDisplay ? (
              <ManpowerLoadingSkeleton />
            ) : (
              <>
                {(displayedData as IDashboardManpowerSectionCompany[])
                  ?.length ? (
                  (displayedData as IDashboardManpowerSectionCompany[])?.map(
                    (item, index) => {
                      const ticketColors = getRandomColorsFromArray(
                        item.tickets?.length || 0,
                        COLORS_FOR_MANPOWER_SECTION,
                      );
                      return (
                        <Container
                          className={cn(
                            'bg-trafficWhite border-none md:px-1.5 md:py-2 px-1.5 py-2 ',
                            {
                              'px-0.5 md:px-0.5 py-1 md:py-1 rounded-lg':
                                cardDisplay,
                            },
                          )}
                          key={item._id}
                        >
                          <Accordion
                            type='single'
                            collapsible
                            value={openItems}
                            onValueChange={handleAccordionChange}
                            defaultValue={index === 0 ? item._id : undefined}
                          >
                            <AccordionItem
                              value={item._id}
                              className='border-b-0'
                            >
                              <AccordionTrigger
                                className={cn(
                                  'font-bold text-sm px-3 py-2 hover:no-underline text-start bg-lightSky rounded-xl ',
                                  {
                                    'px-1.5 md:px-1.5 py-0 md:py-0 rounded-lg':
                                      cardDisplay,
                                  },
                                )}
                              >
                                <div
                                  className={cn(
                                    'w-full grid xs:grid-cols-5 gap-1',
                                    {
                                      'lg:flex-col xl:flex-row': !sidebarExpand,
                                    },
                                  )}
                                >
                                  <Typography
                                    className={cn(
                                      'text-xs md:text-sm col-span-5 xs:col-span-2 xl:col-span-2 font-bold w-full capitalize truncate',
                                      {
                                        'text-[7px] md:text-[7px]': cardDisplay,
                                        'lg:col-span-2': sidebarExpand,
                                        'lg:col-span-5': haveCharts,
                                      },
                                    )}
                                  >
                                    {item.companyName.length > 30
                                      ? `${item.companyName.substring(0, 30)}...`
                                      : item.companyName}
                                  </Typography>
                                  <div
                                    className={cn(
                                      'col-span-5 xs:col-span-3 xl:col-span-3 gap-1 w-full pr-1 grid grid-cols-2',
                                      {
                                        'gap-1': cardDisplay,
                                        'lg:col-span-3': sidebarExpand,
                                        'lg:col-span-5 ': haveCharts,
                                      },
                                    )}
                                  >
                                    <ManpowerSectionDetailItem
                                      label={staticText.activeReq}
                                      value={formatNumber(
                                        item?.activeRecruiter || 0,
                                      )}
                                      valueClassName='font-bold '
                                      cardDisplay={cardDisplay}
                                    />
                                    <ManpowerSectionDetailItem
                                      label={staticText.noOfPosition}
                                      value={formatNumber(
                                        item?.numberOfPositions || 0,
                                      )}
                                      valueClassName='font-bold'
                                      cardDisplay={cardDisplay}
                                    />
                                  </div>
                                </div>
                              </AccordionTrigger>
                              <AccordionContent
                                className={cn('mt-3 pb-0 space-y-3', {
                                  'mt-1': cardDisplay,
                                })}
                              >
                                {item.tickets?.map((ticket, ticketIndex) => (
                                  <Container
                                    className={cn(
                                      'grid p-3 md:p-3 grid-cols-2 gap-3 sm:grid-cols-4 ',
                                      {
                                        'p-1 md:p-1 rounded-lg': cardDisplay,
                                        'lg:grid-cols-2 xl:grid-cols-4':
                                          sidebarExpand,
                                        'lg:grid-cols-4': !haveCharts,
                                      },
                                    )}
                                    key={ticket._id}
                                  >
                                    <ManpowerSectionDetailItem
                                      label={staticText.ticketNo}
                                      value={formatNumber(ticket.ticketNumber)}
                                      cardDisplay={cardDisplay}
                                    />
                                    <ManpowerSectionDetailItem
                                      label={staticText.jobPosition}
                                      value={ticket.jobPosition}
                                      cardDisplay={cardDisplay}
                                    />
                                    <ManpowerSectionDetailItem
                                      label={staticText.vacancies}
                                      value={`${ticket.candidatesHired || 0} / ${ticket.numberOfHC}`}
                                      cardDisplay={cardDisplay}
                                    />
                                    <div className='flex flex-col justify-center'>
                                      <Typography
                                        className={cn(
                                          'text-xs md:text-[13px] font-bold mb-1',
                                          {
                                            'text-[7px] md:text-[7px] mb-0':
                                              cardDisplay,
                                          },
                                        )}
                                      >
                                        {ticket.completePercentage}%
                                      </Typography>
                                      <Progress
                                        value={ticket.completePercentage}
                                        className={cn(
                                          'h-2 w-14 bg-opacity-20',
                                          cardDisplay
                                            ? 'bg-primary'
                                            : ticketColors[ticketIndex],
                                          { 'h-1 w-8': cardDisplay },
                                        )}
                                        fillColor={
                                          cardDisplay
                                            ? 'bg-primary'
                                            : ticketColors[ticketIndex]
                                        }
                                      />
                                    </div>
                                  </Container>
                                ))}
                              </AccordionContent>
                            </AccordionItem>
                          </Accordion>
                        </Container>
                      );
                    },
                  )
                ) : (
                  <StateIndicator
                    state='Empty'
                    noDataMessage={common.noDataAvailable}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ManpowerRequestFormSection;
