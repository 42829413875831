import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { ICandidate } from '@/@types/candidate';
import {
  IChatResponse,
  ICreateBulkChatResponse,
  ICreateChatPayload,
  ICreateChatResponse,
  IGetTicketsForAssociationParams,
  IMessage,
  INewMesssageTemplate,
  ITemplate,
} from '@/@types/chat';
import { ITicket } from '@/@types/tickets';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { strings } from '@/locales';
import { buildQueryParams } from '@/utils/common';
import { QueryClient } from '@tanstack/react-query';

export const getChatList = async (
  isRecruiter?: boolean,
  search?: string,
): Promise<IChatResponse | boolean> => {
  const queryParams = buildQueryParams({
    isRecruiter,
    search,
    isCompanyClient: !isRecruiter,
  });

  const response = await API.Get<IChatResponse>(
    `${REST_SUB_URL.GET_CHAT_LIST}?${queryParams.toString()}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const createChat = async (
  payload: ICreateChatPayload,
): Promise<ICreateChatResponse | boolean> => {
  const response = await API.Post<ICreateChatPayload, ICreateChatResponse>(
    REST_SUB_URL.CREATE_CHAT,
    payload,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  response.message === strings.chatScreen.conversationExist
    ? null
    : toast.error(response.message);
  return false;
};

export const getChatById = async (
  conversationID: string,
  page: string,
): Promise<IPaginationResponse<IMessage> | boolean> => {
  const response = await API.Get<IPaginationResponse<IMessage>>(
    `${REST_SUB_URL.GET_CHAT}/${conversationID}?page=${page}`,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const getTicketsForAssociation = async ({
  page,
  search,
}: IGetTicketsForAssociationParams): Promise<
  IPaginationResponse<ITicket> | boolean
> => {
  const queryString = buildQueryParams({ page, search });
  const response = await API.Get<IPaginationResponse<ITicket>>(
    `${REST_SUB_URL.ASSOCIATE_CHAT}?${queryString}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const getCandidatesListForChat = async (
  page: string,
  search?: string,
): Promise<IPaginationResponse<ICandidate> | boolean> => {
  const queryString = buildQueryParams({ page, search });

  const response = await API.Get<IPaginationResponse<ICandidate>>(
    `${REST_SUB_URL.CHAT_CANDIDATE_LIST}?${queryString}`,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const readChatMessages = async (
  conversationId: string,
  queryClient: QueryClient,
): Promise<boolean> => {
  const response = await API.Get<boolean>(
    `${REST_SUB_URL.READ_MESSAGES}/${conversationId}`,
  );
  if (response.status) {
    queryClient.invalidateQueries({
      queryKey: ['chatList'],
    });
    return true;
  }
  toast.error(response.message);
  return false;
};

export const createBulkChat = async (
  payload: ICreateChatPayload[],
): Promise<ICreateBulkChatResponse | boolean> => {
  const response = await API.Post<
    ICreateChatPayload[],
    ICreateBulkChatResponse
  >(REST_SUB_URL.CREATE_BULK_CHAT, payload);
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const getTemplates = async (
  page: string,
  search?: string,
): Promise<IPaginationResponse<ITemplate> | boolean> => {
  console.log('HERE', page);
  const queryString = buildQueryParams({ page, search });
  const response = await API.Get<IPaginationResponse<ITemplate>>(
    `${REST_SUB_URL.CHAT_TEMPLATES}?${queryString}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const createTemplate = async (
  payload: INewMesssageTemplate,
): Promise<INewMesssageTemplate | boolean> => {
  const response = await API.Post<INewMesssageTemplate, ITemplate>(
    REST_SUB_URL.CHAT_TEMPLATES,
    payload,
  );
  if (response.status && response?.data) {
    toast.success(response.message);
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const deleteTemplate = async (
  id: string,
): Promise<boolean | boolean> => {
  const response = await API.Delete<string, boolean>(
    `${REST_SUB_URL.CHAT_TEMPLATES}/${id}`,
  );
  if (response.status) {
    toast.success(response.message);
    return true;
  }

  toast.error(response.message);
  return false;
};
