import { Typography } from '@/components/Typography';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';

interface IManpowerSectionDetailItem {
  label: string;
  value: string | number;
  valueClassName?: string;
  cardDisplay?: boolean;
  labelClassName?: string;
  containerClassName?: string;
  loading?: boolean;
  totals?: boolean;
}

const ManpowerSectionDetailItem: React.FC<IManpowerSectionDetailItem> = ({
  label,
  value,
  valueClassName,
  cardDisplay,
  labelClassName,
  containerClassName,
  loading,
  totals = false,
}) => {
  return (
    <div className={cn('flex flex-col', containerClassName)}>
      <Typography
        className={cn(
          'text-xs md:text-[13px] font-bold truncate',
          {
            'text-[6px] md:text-[6px]': cardDisplay,
          },
          { 'sm:font-extrabold': totals },
          labelClassName,
        )}
      >
        {totals ? `Total ${label}` : label}
      </Typography>
      <Typography
        className={cn(
          'text-xs md:text-[13px] font-semibold text-primary mt-1 truncate',
          {
            'text-[7px] md:text-[7px] -mt-2': cardDisplay,
          },
          { 'text-base md:text-base sm:font-extrabold': totals },
          valueClassName,
        )}
      >
        {loading ? <Skeleton className='w-4 h-3 rounded-sm' /> : value}
      </Typography>
    </div>
  );
};
export default ManpowerSectionDetailItem;
